import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import { ParallaxBanner } from 'react-scroll-parallax';

import styles from './subPageHero.module.css'

const subPageHero = ({heroText, heroBackground}) => (
  <ParallaxBanner
      className={styles.root + ' hero-wrapper'}
      layers={[
          {
            image: heroBackground.heroBackground,
            amount: 0.2,
          },
          // {
          //   image: tree,
          //   amount: 0.08,
          //   expanded: false,
          //   props: {
          //     id: 'tree',
          //     style: {
          //       backgroundSize: '25% auto',
          //       backgroundRepeat: 'no-repeat',
          //       backgroundPosition: '100% bottom',
          //     }
          //   },
          // },
      ]}
      style={{
          height: '380px'
      }}
  >
    <div className={styles.wrapper}>
      <h1 className={styles.heroCopy}>{heroText}</h1>
    </div>
  </ParallaxBanner>
)

export default subPageHero
