import React from 'react'
import {Link} from 'gatsby'
import {cn} from '../lib/helpers'
import Img from "gatsby-image/withIEPolyfill"

import styles from './aboutPageContent.module.css'

const AboutPageContent = (props) => console.log(props) || (
  <>
    <section className={styles.sectionBoxed}>
      <h2 className={styles.introHeader}>This is a story all about how...</h2>
      <div className={styles.intro}>
        <Img fluid={props.yearbookPhoto ? props.yearbookPhoto.childImageSharp.fluid : {}} alt="Yearbook Photo" className={styles.highSchoolHeadshot + ' imageObject'}/>
        <p>Born and raised in Georgia, I got my start coding at the age of 14. By the time I was 17 I was building my schools website while also working with dozens of small companies across the southeast. Over the more than 16 years since, I’ve helped hundreds of clients and creative agencies of all sizes grow and expand their businesses online. My love of sunshine and adventure along with the allure of the west coast startup culture made the move from Atlanta to Los Angeles an easy decision. Amazing weather, delicious street tacos and meeting my beautiful wife all led me to make LA my permanent home.</p>
        <p>While here in LA, I’ve been able to expand my work to include clients like Tom’s, Lamborghini, Panna Cooking, Family Dollar & more. </p>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
      <div className={styles.hawaii}>
        <div className={styles.hawaiiImages + ' imageObject'}>
          <Img fluid={props.hawaiiAdventures ? props.hawaiiAdventures.childImageSharp.fluid : {}} alt="Hawaii Adventures"/>
          <caption className={'small'}>(top) Pride of Aloha  docked in Kona, Hiwaii. (bottom) Some guy on a laptop</caption>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tristique et viverra vitae nunc quisque non sed. Id diam justo, molestie in ante. Euismod quis viverra eros, consequat quam et fermentum. Eu elit posuere mattis tempus tincidunt hac augue at. Quis ut proin magna duis potenti elit auctor quam. Nulla amet sollicitudin feugiat quis leo, ultricies faucibus adipiscing nunc. Fermentum, ut sit duis neque, vitae dictum duis.</p>
        <p>Dignissim mi velit massa donec. Lacus eros congue sit vel in ultrices. Senectus vehicula mauris velit nec non aliquam, dolor. Enim sagittis, urna massa non sed in justo sit dui. At erat gravida laoreet volutpat ut pellentesque sed. Tincidunt feugiat viverra nulla quam convallis. Magna cursus bibendum diam massa sit.Tincidunt arcu ullamcorper quis mattis turpis dui at. Nulla etiam ac in tortor. Iaculis eu suscipit morbi elit velit enim aliquet arcu. Quisque cursus in dignissim fringilla placerat at tincidunt turpis. Magna semper elementum iaculis ac volutpat eu placerat mauris sit. Sit ac ultrices nec orci imperdiet eget eget egestas. Lorem neque fringilla id vitae in tellus. Gravida nec fames ut in dui malesuada nibh sem. Scelerisque maecenas nisl id et faucibus aenean. Massa odio hendrerit in vel iaculis erat ac velit.</p>
      </div>
    </section>
    <section className={styles.sectionFullWidth + ' ' + styles.imageRow}>
      <div className={'imageObject yellowStoneImage'}>
        <Img fluid={props.yellowstoneS2k ? props.yellowstoneS2k.childImageSharp.fluid : {}} alt="Driving my s2000 through yellowstone"/>
        <caption className={'small'}>Trying to be cool while passing through yellowstone in my s2000</caption>
      </div>
      <div className={'imageObject yellowStoneImage'}>
        <Img fluid={props.yellowstoneBuffalo ? props.yellowstoneBuffalo.childImageSharp.fluid : {}} alt="buffalo in yellowstone"/>
        <caption className={'small'}>Passed a ton of buffalo in Yellowstone.</caption>
      </div>
      <div className={'imageObject yellowStoneImage'}>
        <Img fluid={props.welcomeToCali ? props.welcomeToCali.childImageSharp.fluid : {}} alt="Welcome to California sign"/>
        <caption className={'small'}>After two weeks and nearly 3000 miles I finally made it to California.</caption>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
      <div className={styles.contentRow}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices nullam nisl eget posuere nisi, orci elementum. Id cras mollis nulla lobortis. A, lacus sit maecenas egestas varius egestas urna. Enim velit varius arcu auctor aliquet interdum ornare mattis nunc. Eget risus nisi elit enim, congue tempus, odio velit, leo.  </p>
        <p>Justo tortor urna leo nam nunc ultrices rutrum quam. Arcu consequat a sit mauris sollicitudin augue. Eget eros, purus quisque nibh. Euismod scelerisque eu mus vitae varius. Est enim tempor malesuada mauris habitasse. Enim leo vestibulum ac mus et morbi cras. Tincidunt ultrices tortor lectus quis non. Condimentum tellus varius scelerisque commodo erat pulvinar ullamcorper morbi. Ac pellentesque morbi donec aliquam. Odio non elementum faucibus adipiscing.
        </p>
      </div>
    </section>
    <section className={styles.sectionBoxed + ' ' + styles.imageRow}>
      <div className={'imageObject blockImage'}>
        <Img fluid={props.claudiaAndI ? props.claudiaAndI.childImageSharp.fluid : {}} alt="My wife and i on our first adventure"/>
        <caption className={'small'}>I managed to convince  this beautiful lady (Now my wife) to join me on my adventures.</caption>
      </div>
      <div className={'imageObject blockImage'}>
        <Img fluid={props.goodbyeToms ? props.goodbyeToms.childImageSharp.fluid : {}} alt="Goodbye Toms"/>
        <caption className={'small'}>Saying goodbye to TOMS before setting off on my next adventure.</caption>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
      <div className={styles.contentRow}>
        <p>Justo tortor urna leo nam nunc ultrices rutrum quam. Arcu consequat a sit mauris sollicitudin augue. Eget eros, purus quisque nibh. Euismod scelerisque eu mus vitae varius. Est enim tempor malesuada mauris habitasse. Enim leo vestibulum ac mus et morbi cras. Tincidunt ultrices tortor lectus quis non. Condimentum tellus varius scelerisque commodo erat pulvinar ullamcorper morbi. Ac pellentesque morbi donec aliquam. Odio non elementum faucibus adipiscing.
        </p>
      </div>
    </section>
    <section className={styles.sectionFullWidth + ' ' + styles.unBalanced}>
      <div className={styles.content}>
        <div className={styles.imageBleed + ' imageObject'}>
          <Img fluid={props.brokenDown ? props.brokenDown.childImageSharp.fluid : {}} alt="My Jeep Broken down in Texas"/>
          <caption className={'small'}>Broken down in the middle of nowhere in Texas.</caption>
        </div>
        <p>Justo tortor urna leo nam nunc ultrices rutrum quam. Arcu consequat a sit mauris sollicitudin augue. Eget eros, purus quisque nibh. Euismod scelerisque eu mus vitae varius. Est enim tempor malesuada mauris habitasse. Enim leo vestibulum ac mus et morbi cras. Tincidunt ultrices tortor lectus quis non. Condimentum tellus varius scelerisque commodo erat pulvinar ullamcorper morbi. Ac pellentesque morbi donec aliquam. Odio non elementum faucibus adipiscing.
        </p>
        <p>Justo tortor urna leo nam nunc ultrices rutrum quam. Arcu consequat a sit mauris sollicitudin augue. Eget eros, purus quisque nibh. Euismod scelerisque eu mus vitae varius. Est enim tempor malesuada mauris habitasse. Enim leo vestibulum ac mus et morbi cras. Tincidunt ultrices tortor lectus quis non. Condimentum tellus varius scelerisque commodo erat pulvinar ullamcorper morbi. Ac pellentesque morbi donec aliquam. Odio non elementum faucibus adipiscing.
        </p>
        <br />
      </div>
    </section>
   
    <section className={styles.sectionFullWidth + ' ' + styles.imageRow}>
      <div className={'imageObject costaRica'}>
        <Img fluid={props.costaRicaSnorkeling ? props.costaRicaSnorkeling.childImageSharp.fluid : {}} alt="Snorkeling in Costa Rica"/>
        <caption className={'small'}>In Costa Rica, Snorkeling and scuba diving became a near daily activity.</caption>
      </div>
      <div className={'imageObject costaRica'}>
        <Img fluid={props.costaRicaSelfie ? props.costaRicaSelfie.childImageSharp.fluid : {}} alt="Selfie in costa rica"/>
        <caption className={'small'}>My (now) wife and I waiting to board a bus in Costa Rica. </caption>
      </div>
      <div className={'imageObject costaRica'}>
        <Img fluid={props.myMonkeyFriend ? props.myMonkeyFriend.childImageSharp.fluid : {}} alt="Me and a monkey"/>
        <caption className={'small'}>I met a new friend.</caption>
      </div>
    </section>
    <section className={styles.sectionFullWidth + ' ' + styles.imageRow}>
      <div className={'imageObject costaRica'}>
        <Img fluid={props.costaRicaHike ? props.costaRicaHike.childImageSharp.fluid : {}} alt="Me and a monkey"/>
        <caption className={'small'}>My wife looking out over a valley during one of our hikes.</caption>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
      <div className={styles.contentRow}>
      <p>Molestie ut dui faucibus dolor tellus a libero. Nec tincidunt duis leo, convallis aliquam. In porttitor mauris nibh in facilisis commodo vitae tellus. Aliquam, sit molestie a, aliquam. Tempus sollicitudin risus tempus id ullamcorper amet, arcu non semper. </p>
      </div>
    </section>
    <section className={styles.sectionBoxed + ' ' + styles.imageRow + ' ' + styles.weddingImages}>
      <div className={'imageObject'}>
        <Img fluid={props.weddingPhoto ? props.weddingPhoto.childImageSharp.fluid : {}} alt="Wedding Photos at LACMA"/>
        <caption className={'small'}>Wedding photo at LACMA</caption>
      </div>
      <div className={styles.priceIsRightImages}>
        <div className={'imageObject'}>
          <Img fluid={props.priceIsRightWheel ? props.priceIsRightWheel.childImageSharp.fluid : {}} alt="Claudia and I spinning the big wheel on price is right"/>
        </div>
        <div className={'imageObject'}>
          <Img fluid={props.priceIsRightShowcase ? props.priceIsRightShowcase.childImageSharp.fluid : {}} alt="Claudia and I on the showcase Showdown!"/>
          <caption className={'small'}>(Top Right) We had the winning spin! (Bottom Right) Showcase Showdown!</caption>
        </div>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
      <div className={styles.contentRow}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin consequat habitant est rhoncus sed accumsan euismod volutpat dolor. Rutrum lorem quis risus enim, magna tincidunt sed nec. Vulputate semper aliquet in elementum elit. Aliquam posuere enim egestas id nibh duis. Risus nunc velit, pellentesque facilisis orci, in egestas dictumst. Feugiat sed felis turpis a maecenas tellus iaculis pretium. Mattis aliquet rutrum nunc eu in. Aliquet quis nibh urna in massa vulputate faucibus tellus.  </p>
        <p>Mauris viverra nullam sit nunc augue at volutpat. In ut potenti velit adipiscing dignissim quam aenean turpis. Cursus sed tempus et aenean nec, amet. Quis lorem est faucibus posuere massa diam scelerisque neque. Etiam pellentesque ultricies ut nulla vitae eu tempus.
        </p>
      </div>
    </section>
    <section className={styles.sectionBoxed + ' ' + styles.imageRow + ' ' + styles.weddingImages}>
      <div>
        <div className={'imageObject'}>
          <Img fluid={props.maternityPhoto ? props.maternityPhoto.childImageSharp.fluid : {}} alt="Claudia and I maternity photos"/>
        </div>
        <div className={'imageObject'}>
          <Img fluid={props.waterSlide ? props.waterSlide.childImageSharp.fluid : {}} alt="My son Lucas and i on waterslide"/>
          <caption className={'small'}>(top left) Maternity photos in the park. (bottom left) Lucas' first time on a waterslide. </caption>
        </div>
      </div>
      <div className={styles.priceIsRightImages}>
        <div className={'imageObject'}>
          <Img fluid={props.dawgsFamily ? props.dawgsFamily.childImageSharp.fluid : {}} alt="Whole family dressed for the Ga Bulldogs Football game!"/>
        </div>
        <div className={'imageObject'}>
          <Img fluid={props.firstBirthday ? props.firstBirthday.childImageSharp.fluid : {}} alt="Lucas' first birthday photos"/>
        </div>
        <div className={'imageObject'}>
        <Img fluid={props.bigBrotherLucas ? props.bigBrotherLucas.childImageSharp.fluid : {}} alt="Lucas looking cool"/>
          <caption className={'small'}>(top) Go GA Bulldogs! (middle) Lucas' first birthday. (bottom) Ready to be a big bro!</caption>
        </div>
      </div>
    </section>
    <section className={styles.sectionBoxed}>
        <h3 className={'alignCenter ' + styles.toBeContinued}><Link to={'/professional'}>See my Professional Work Experience (Résumé) >></Link></h3>
    </section>
  </>
)

export default AboutPageContent
