import React from 'react'
import {graphql} from 'gatsby'

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/subPageHero'
import AboutPageContent from '../components/aboutPageContent'

import heroBackground from '../assets/images/hero-background.jpg'

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    },
    yearbookPhoto: file(relativePath: { eq: "about/my-highschool-headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    hawaiiAdventures: file(relativePath: { eq: "about/hawaii-adventures.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    yellowstoneS2k: file(relativePath: { eq: "about/yellowstone-s2k.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    yellowstoneBuffalo: file(relativePath: { eq: "about/yellowstone-buffalos.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    welcomeToCali: file(relativePath: { eq: "about/welcome-to-cali.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    brokenDown: file(relativePath: { eq: "about/broke-down-in-texas.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    myMonkeyFriend: file(relativePath: { eq: "about/my-monkey-friend.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    costaRicaSelfie: file(relativePath: { eq: "about/costa-rica-arrival.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    costaRicaSnorkeling: file(relativePath: { eq: "about/costa-rica-snorkeling.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    costaRicaHike: file(relativePath: { eq: "about/costa-rica-hike.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    weddingPhoto: file(relativePath: { eq: "about/wedding-photo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    priceIsRightWheel: file(relativePath: { eq: "about/price-is-right-wedding-wheel.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    priceIsRightShowcase: file(relativePath: { eq: "about/price-is-right-wedding-showcase.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    goodbyeToms: file(relativePath: { eq: "about/goodbye-toms.jpg" }) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        },
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    claudiaAndI: file(relativePath: { eq: "about/claudia-and-me-in-la.jpg" }) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        },
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    maternityPhoto: file(relativePath: { eq: "about/maternity-photoshoot.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    waterSlide: file(relativePath: { eq: "about/lucas-waterslide-birthday.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    firstBirthday: file(relativePath: { eq: "about/lucas-first-birthday.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    bigBrotherLucas: file(relativePath: { eq: "about/lucas-big-bro-shirt.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    dawgsFamily: file(relativePath: { eq: "about/dawgs-family.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
 const photos = [
   (data || {}).hawaiiAdventures
 ]
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Hero heroBackground={{heroBackground}} heroText={'My Story'} />
        <h1 hidden>Welcome to {site.title}</h1>
        <AboutPageContent
          yearbookPhoto={data.yearbookPhoto}
          hawaiiAdventures={data.hawaiiAdventures}
          yellowstoneS2k={data.yellowstoneS2k}
          yellowstoneBuffalo={data.yellowstoneBuffalo}
          welcomeToCali={data.welcomeToCali}
          brokenDown={data.brokenDown}
          myMonkeyFriend={data.myMonkeyFriend}
          costaRicaSelfie={data.costaRicaSelfie}
          costaRicaSnorkeling={data.costaRicaSnorkeling}
          costaRicaHike={data.costaRicaHike}
          weddingPhoto={data.weddingPhoto}
          priceIsRightWheel={data.priceIsRightWheel}
          priceIsRightShowcase={data.priceIsRightShowcase}
          goodbyeToms={data.goodbyeToms}
          claudiaAndI={data.claudiaAndI}
          maternityPhoto={data.maternityPhoto}
          waterSlide={data.waterSlide}
          firstBirthday={data.firstBirthday}
          bigBrotherLucas={data.bigBrotherLucas}
          dawgsFamily={data.dawgsFamily}
        />
      </Container>
    </Layout>
  )
}

export default AboutPage
